<template>
  <div >
    <template v-if="!isMobile">
      <div class="box"  v-if="languageSelect==='zh'">
        <div class="ViewTitle">{{ title }}</div>
        <div class="contain gery">
          <el-form
              :label-position="labelPosition"
              label-width="110px"
              :model="form"
          >
            <el-form-item label="区号">
              <el-select v-model="form.areaCode" placeholder="请选择区号">
                <el-option
                    v-for="(item, index) in cityCode"
                    :key="index"
                    :label="`${item.cn} (+${item.code})`"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" class="codeBox">
              <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
              <el-button
                  type="text"
                  :disabled="disabled"
                  class="setCode"
                  @click="getCode()"
              >{{ setCode }}</el-button
              >
            </el-form-item>
            <div class="submit" @click="submit()">确认</div>
          </el-form>
        </div>
      </div>
      <div class="box"  v-if="languageSelect==='en'">
        <div class="ViewTitle">{{ titleEn }}</div>
        <div class="contain gery">
          <el-form
              :label-position="labelPosition"
              label-width="180px"
              :model="form"
          >
            <el-form-item label="Area code">
              <el-select v-model="form.areaCode" placeholder="Please select an area code">
                <el-option
                    v-for="(item, index) in cityCode"
                    :key="index"
                    :label="`${item.en} (+${item.code})`"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Phone">
              <el-input v-model="form.phone" placeholder="Please enter your phone number"></el-input>
            </el-form-item>
            <el-form-item label="Verification code" class="codeBox">
              <el-input v-model="form.code" placeholder="Verification code"></el-input>
              <el-button
                  type="text"
                  :disabled="disabled"
                  class="setCode"
                  @click="getCodeEn()"
              >{{ setCodeEn }}</el-button
              >
            </el-form-item>
            <div class="submit" @click="submitEn()">Confirm</div>
          </el-form>
        </div>
      </div>
    </template>
    <div v-else class="m-secure-page"> 
      <Header :title="title"></Header>
      <div class="m-secure-box">
        <van-field v-model="areaCode" :label="$t('user.areaCode')" :placeholder="$t('user.areaCodeTip')" readonly required @click="showPicker = true" />
        <van-field v-model="form.phone" :label="$t('user.phone')" type="tel" :placeholder="$t('user.phoneTip')" required />
        <van-field
          v-model="form.code"
          center
          clearable
          required
          :label="$t('user.code')"
          :placeholder="$t('user.codeTip')"
        >
          <template #button>
            <van-button :disabled="disabled" size="small" type="primary" @click="($i18n.locale == 'en' ? getCodeEn() : getCode())">{{ $i18n.locale == 'en' ?  setCodeEn : setCode }}</van-button>
          </template>
        </van-field>

        <div class="m-submit-btn" @click="($i18n.locale == 'en' ? submitEn() : submit())">{{ $t('common.confirm') }}</div>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          :title="$t('user.areaCode')"
          show-toolbar
          :columns="formatCity()"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      <Bottom></Bottom>
    </div>
  </div>

</template>
<script>
import city from "@/utils/city";
import { phoneCode, upPhone } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";
import {  Field, Button, Picker, Popup } from 'vant';

export default {
  name: "ChangePhone",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    Bottom,
    Header
  },
  data() {
    return {
      title: "修改手机号",
      titleEn:'Modify mobile phone number',
      labelPosition: "right",
      form: {
        areaCode: "",
        phone: "",
        code: "",
      },
      cityCode: city.CityOverseasgx,
      disabled: false,
      setCode: "发送验证码",
      setCodeEn: "Verification code",
      languageSelect: '',
      isMobile: false,
      showPicker: false,
      areaCode: ''
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    let type = this.$route.query.type;
    this.title = type == 1 ? this.$t('user.modifyPhone') : this.$t('user.bindingPhone') ;
    document.title = this.title
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气知识',
        text:'洞悉油气行业变化，体验AI大模型，就来油气知识！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  mounted(){
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    formatCity(){
      return city.CityOverseasgx.map(item => {
        return  `${item[this.$i18n.locale == 'en' ? 'en' : 'cn']}-${item.code}`;
      })
    },
    onCancel(){
      this.showPicker = false;
    },
    onConfirm(val){
      this.showPicker = false;
      this.areaCode = val
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      if(this.areaCode){
        this.form.areaCode = this.areaCode.split('-')[1]
      }
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("请选择区号");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      let data = {
        phone: this.form.phone,
        areaCode: this.form.areaCode,
        event: "changemobile",
      };
      phoneCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtn(); //验证码倒数60秒
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    submit() {
      // eslint-disable-next-line no-unreachable
      if(this.areaCode){
        this.form.areaCode = this.areaCode.split('-')[1]
      }
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("请选择区号");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      upPhone(this.form).then(() => {
        this.$message({
          message: "修改成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            this.getUserCom();
            this.$router.go(-1);
          },
        });
      });
    },
    getCodeEn() {
      if(this.areaCode){
        this.form.areaCode = this.areaCode.split('-')[1]
      }
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("Please select an area code");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("Please enter your phone number");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("Please enter the correct phone number");
        return false;
      }
      let data = {
        phone: this.form.phone,
        areaCode: this.form.areaCode,
        event: "changemobile",
      };
      phoneCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtnEn(); //验证码倒数60秒
      });
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn = "Try again in"+time + " secondS";
          time--;
        }
      }, 1000);
    },
    submitEn() {
      // eslint-disable-next-line no-unreachable
      if(this.areaCode){
        this.form.areaCode = this.areaCode.split('-')[1]
      }
      const reg = /^\d{6,13}$/;
      if (!this.form.areaCode) {
        this.$message.error("Please select an area code");
        return false;
      }
      if (!this.form.phone) {
        this.$message.error("Please enter your phone number");
        return false;
      }
      if (!reg.test(this.form.phone)) {
        this.$message.error("Please enter the correct phone number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      upPhone(this.form).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            this.getUserCom();
            this.$router.go(-1);
          },
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    padding: 42px 30px;
    width: fit-content;
    .el-form-item {
      margin-bottom: 36px;
    }
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border-radius: 28px;
      margin: 0 auto;
      margin-top: 20px;
      background: var(--main-color);
      color: #ffffff;
      cursor: pointer;
    }
  }
}

::v-deep {
  .el-input__inner {
    height: 48px;
  }

  .el-form-item__label {
    color: #666;
    font-size: 16px;
    padding-right: 32px;
    line-height: 48px;
  }
  .codeBox {
    .el-form-item__content {
      display: flex;
      .el-input {
        flex: 1;
      }
      .setCode {
        width: 120px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}

.m-secure-page{
  background-color: #f8f8f8;
  .m-secure-box{
    margin: 0 20px;
    min-height: 74vh;
  }
  .m-submit-btn{
    margin: 20px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 30px;
    background-color: var(--main-bg-color);
  }
}
</style>

